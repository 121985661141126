import React, {useState, useRef, useEffect} from "react";
import Header from "../../components/Header/Header";
import * as classes from "./services.module.css";
import content from "../../../content.json";
import { Link } from "react-scroll";
import arrow from "../../assets/images/others/Group1button.png";
const Services = (props) => {
  const servicesList = Object.entries(content.servicesPage);

  const isBrowser = () => typeof window !== "undefined";

  let url = "";
  let service_id = 0;
  if(isBrowser()){
    url = new URL(window.location.href);
    service_id = url.searchParams.get("id");
  }
  const [ind, setInd] = useState(service_id || 0);
  const arrowRef = useRef();

  useEffect(() => {
    if(arrowRef.current){
      setTimeout(() => {
        arrowRef.current.click();
      }, 2000);
    }
  },[])


  const nextScroll = () => {
    if(ind == servicesList.length - 1){
      setInd(0)
    }else{
      setInd(parseInt(ind)+1)
    }
  }
  return (
    <>
      <Header />
      <div className={classes.ServicesContainer}>
        <div className={classes.LeftContainer} id="SectionId">
          {servicesList.map((services, index) => {
            return (
              <div
                className={classes.LeftServiceContainer}
                key={index}
                id={"Servicesdiv" + index}
              >
                <div className={classes.ServiceHeading}>
                  <p>{services[1].name}</p>
                </div>
                <div className={classes.ServiceInfoContainer}>
                  <p>{services[1].description}</p>
                </div>
                {services[1].points.map((tools, index) => {
                  return (
                    <div
                      className={classes.ToolsContainer}
                      key={index}
                      id="SectionListId"
                    >
                      <p>
                        <span>•</span>
                        {tools}
                      </p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <Link
          to={"Servicesdiv"+ind}
          spy={true}
          smooth={true}
          offset={0}
          duration={1000}
        >
          <div className={classes.ArrowContainer} onClick={nextScroll} ref={arrowRef}>
            <img src={arrow} alt="arrow" />
          </div>
        </Link>
      </div>
    </>
  );
};
export default Services;
